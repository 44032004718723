import { fg } from '@atlaskit/platform-feature-flags';

function getServiceUrl(cloudId: string, baseUrlOverride?: string) {
	const maybeShardedPath = fg('enable_ptc_sharded_townsquare_calls')
		? `/townsquare/s/${cloudId}`
		: '/watermelon';
	const path = `/gateway/api${maybeShardedPath}/workspace/existsWithWorkspaceType?cloudId=${cloudId}`;
	if (baseUrlOverride) {
		return `${baseUrlOverride}${path}`;
	}
	return path;
}

export async function isGlobalExperienceWorkspaceForCloudId(
	cloudId?: string,
	baseUrlOverride?: string,
): Promise<boolean> {
	if (cloudId === undefined) {
		return false;
	}

	try {
		const response = await fetch(getServiceUrl(cloudId, baseUrlOverride), {
			credentials: 'include',
		});
		if (response.ok) {
			const workspaceType = await response.text();
			return workspaceType === 'GLOBAL_EXPERIENCE';
		}
		return false;
	} catch (err) {
		return false;
	}
}
