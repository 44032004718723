import React from 'react';
import { Text } from '@atlaskit/primitives';
import { useIntl } from 'react-intl-next';

import {
	type ResultRendererArgs,
	type TitleRendererArgs,
} from '../../product-router/product/result-types';
import { messages } from '../../../messages';
import { ProductStates } from '../../product-state-machine/product-state-machine-types';
import { LoadingSkeleton } from '../../autocorrection/autocorrection.styled';
import { AutocorrectAsyncWrapper } from '../../autocorrection';
import { defaultResultRenderer } from '../../search-result/default-result-renderer';
import { getAvatarForConfluenceObjectResult } from './result-icons';

export const sectionTitleRenderer = (autocorrectEnabled: boolean) => (args: TitleRendererArgs) => {
	const { formatMessage } = useIntl();
	const { productState } = args;

	const recentTitle = <Text>{formatMessage(messages.common_recently_viewed_section_heading)}</Text>;
	const contentTitle = <Text>{formatMessage(messages.confluence_content_section_heading)}</Text>;

	switch (productState) {
		case ProductStates.PreQuerySuccess:
			return recentTitle;
		case ProductStates.PostQueryLoading:
			if (autocorrectEnabled) {
				return <LoadingSkeleton data-testid={'autocorrection-loading-skeleton'} />;
			}
			return contentTitle;
		case ProductStates.PostQuerySuccess:
			if (autocorrectEnabled) {
				return <AutocorrectAsyncWrapper fallback={contentTitle} />;
			}
			return contentTitle;
		default:
			return contentTitle;
	}
};

export const ConfluenceItemResultRenderer = ({ searchResult, ...rest }: ResultRendererArgs) => {
	const { formatMessage } = useIntl();
	const { productState } = rest;
	if (productState === 'PreQuerySuccess') {
		return defaultResultRenderer({
			...rest,
			searchResult: {
				...searchResult,
				additionalMeta: '',
			},
		});
	}
	if (productState === 'PostQueryLoading' || productState === 'PostQuerySuccess') {
		return defaultResultRenderer({
			...rest,
			searchResult: {
				...searchResult,
				additionalMeta: !!searchResult.isRecentResult
					? formatMessage(messages.common_recently_viewed_section_heading)
					: searchResult.additionalMeta,
				icon: !!searchResult.isRecentResult
					? getAvatarForConfluenceObjectResult({
							// the recent content type is stored in additionalMeta but not displayed
							type: (searchResult.additionalMeta as string) || '',
							title: searchResult.title,
							isCollapsed: rest.isCollapsed,
						})
					: searchResult.icon,
			},
		});
	}
	return defaultResultRenderer({ searchResult, ...rest });
};
