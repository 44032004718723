import React, { type ReactElement, type ReactNode, useEffect } from 'react';
import { ReturnIcon } from '../icons';
import {
	CollapsedResultContentWrapper,
	CollapsedResultDetail,
	CollapsedResultDetailWrapper,
	DetailsSeparator,
	IconContainer,
	ResultContentWrapper,
	ResultDetail,
	ResultLabel,
	ResultTitleContainer,
	ReturnIconContainer,
} from './search-result.styled';

export interface SearchResultContentProps {
	/**
	 * Display text (and tooltip) for the result item.
	 */
	title: string;

	/**
	 * Populated by the UI filtering logic. This contains the words to be highlighted in the title based on the query
	 */
	highlightedTitle?: string;

	/**
	 * Toggle for whether to show the search results in a collapsed mode. The
	 * collapsed mode is used when width is restricted (e.g. filter pane enabled,
	 * mobile view). This mode will take up more vertical width.
	 */
	isCollapsed?: boolean;

	/**
	 * An icon for the search result.
	 */
	icon?: JSX.Element;

	/**
	 * An label that will be shown before the title. You can use this to display
	 * the id of the item, e.g. issue key.
	 */
	label?: ReactNode;

	/**
	 * Display descriptive text (and tooltip) for the result item.
	 */
	meta?: string | ReactElement;

	/**
	 * Displays any additional metadata for the result item. If provided, this is
	 * rendered to the right of the meta prop. Traditionally used for time details
	 * for Jira search results, e.g. "Recently updated".
	 */
	additionalMeta?: ReactElement | string;

	/**
	 * Whether the search result has been highlited using keyboard navigation.
	 */
	isKeyboardHighlighted: boolean;

	/**
	 * Control the display style of the return icon.
	 */
	returnIcon?: 'onHighlight' | 'always' | 'never';

	/**
	 * Whether the search result is a recent search. This is used to determine
	 * the display style and function of the result item.
	 */
	isRecentSearch?: boolean;

	/**
	 * Suppose to be used for recent searches only.
	 * This is the callback to remove the recent search item.
	 */
	onRemove?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

	/**
	 * Whether to display the additionalMeta text only when the search result is highlighted.
	 */
	displayAdditionalMetaWhenHighlightedOnly?: boolean;
}

export const HIGHLIGHT_START_TAG = '@@@hl@@@';
export const HIGHLIGHT_END_TAG = '@@@endhl@@@';

/**
 * This component is intended to be used as a default for display search result
 * content. It is highly recommended for this component to be wrapped in a
 * SearchResultLink.
 *
 * Since this component is being used to render different entity types,
 * e.g. Search Results, but also Recent Searchers, we should consider
 * renaming to it something more generic at some stage, like SearchDialogItem.
 * Note: renaming it will require a major update since it changes the API.
 */
export const SearchResultContent: React.FC<SearchResultContentProps> = ({
	icon,
	label,
	title,
	meta,
	additionalMeta,
	isCollapsed,
	isKeyboardHighlighted,
}) => {
	const getResultContent = () => (
		<>
			{isCollapsed ? (
				<CollapsedResultContentWrapper data-testid="collapsed-result-view">
					<ResultTitleContainer isCollapsed>
						{typeof label === 'string' ? <ResultLabel title={title}>{label}</ResultLabel> : label}
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766  */}
						<span title={title} className="SearchDialogResultTitle">
							{title}
						</span>
					</ResultTitleContainer>
					<CollapsedResultDetailWrapper>
						{meta && <CollapsedResultDetail title={title}>{meta}</CollapsedResultDetail>}
						{additionalMeta && (
							<>
								<DetailsSeparator>{'•'}</DetailsSeparator>
								<CollapsedResultDetail>{additionalMeta}</CollapsedResultDetail>
							</>
						)}
					</CollapsedResultDetailWrapper>
				</CollapsedResultContentWrapper>
			) : (
				<>
					<ResultTitleContainer>
						{typeof label === 'string' ? <ResultLabel title={title}>{label}</ResultLabel> : label}
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766  */}
						<span title={title} className="SearchDialogResultTitle">
							{title}
						</span>
					</ResultTitleContainer>
					{meta && (
						<ResultDetail title={typeof meta === 'string' ? meta : undefined}>{meta}</ResultDetail>
					)}
					{additionalMeta ? (
						<ResultDetail title={typeof additionalMeta === 'string' ? additionalMeta : undefined}>
							{additionalMeta}
						</ResultDetail>
					) : null}
				</>
			)}
		</>
	);

	const contentRef = React.useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (isKeyboardHighlighted && contentRef.current?.scrollIntoView) {
			contentRef.current.scrollIntoView({
				behavior: 'auto',
				block: 'nearest',
			});
		}
	}, [isKeyboardHighlighted]);

	return (
		<ResultContentWrapper
			ref={contentRef}
			isCollapsed={isCollapsed}
			isKeyboardHighlighted={isKeyboardHighlighted}
		>
			<IconContainer>{icon}</IconContainer>
			{getResultContent()}
			<ReturnIconContainer isCollapsed={isCollapsed} isKeyboardHighlighted={isKeyboardHighlighted}>
				<ReturnIcon inverted />
			</ReturnIconContainer>
		</ResultContentWrapper>
	);
};
