import { type AggResponse } from '../../../../common/utils/fetch-agg';

// Types for AGG graphQL queries are NOT autogenerated in CFE
// This file was manually created
export enum QuickFindAllActivityDataEdgeNodeObjectProduct {
	JIRA = 'jira',
	CONFLUENCE = 'confluence',
	ATLAS = 'townsquare',
}

export enum VIEWED_EVENT {
	VIEWED = 'viewed',
}

export enum WORKED_EVENT {
	COMMENTED = 'commented',
	CREATED = 'created',
	EDITED = 'edited',
	PUBLISHED = 'published',
	UPDATED = 'updated',
}

export type ActivityEventType = VIEWED_EVENT | WORKED_EVENT;

export interface QuickFindAllActivityDataEdgeNodeObjectDataAncestor {
	id: string;
	title: string | null;
}

export interface QuickFindAllActivityDataEdgeNodeObjectDataLink {
	base: string | null;
	webUi: string | null;
}

export interface QuickFindAllActivityDataEdgeObjectDataFieldsByIdJiraFieldEdgeNode {
	text: string | null;
}

export interface QuickFindAllActivityDataEdgeObjectDataFieldsByIdJiraFieldEdge {
	node: QuickFindAllActivityDataEdgeObjectDataFieldsByIdJiraFieldEdgeNode | null;
}

export interface QuickFindAllActivityDataEdgeObjectDataFieldsById {
	edges: QuickFindAllActivityDataEdgeObjectDataFieldsByIdJiraFieldEdge[] | null;
}

export interface QuickFindAllActivityDataEdgeNodeObjectDataSpace {
	id: string | null;
	name: string | null;
}

export interface QuickFindAllActivityDataEdgeNodeObjectData {
	title?: string | null;
	key?: string;
	name?: string;
	pageStatus?: string;
	blogStatus?: string;
	subtype?: string;
	ancestors?: QuickFindAllActivityDataEdgeNodeObjectDataAncestor[] | null;
	links?: QuickFindAllActivityDataEdgeNodeObjectDataLink | null;
	url?: string | null;
	webUrl?: string;
	iconData?: string | null;
	fieldsById?: QuickFindAllActivityDataEdgeObjectDataFieldsById | null;
	space?: QuickFindAllActivityDataEdgeNodeObjectDataSpace | null;
}

export interface QuickFindAllActivityDataEdgeNodeObject {
	id: string;
	type: string;
	product: QuickFindAllActivityDataEdgeNodeObjectProduct;
	data: QuickFindAllActivityDataEdgeNodeObjectData | null;
}

export interface QuickFindAllActivityDataEdgeNodeEvent {
	eventType: ActivityEventType;
	timestamp: string;
}

export interface QuickFindAllActivityDataEdgeNode {
	object: QuickFindAllActivityDataEdgeNodeObject;
	event: QuickFindAllActivityDataEdgeNodeEvent;
}

export interface QuickFindAllActivityDataEdge {
	node: QuickFindAllActivityDataEdgeNode;
}

export interface QuickFindAllActivityData {
	edges: QuickFindAllActivityDataEdge[];
}

export interface QuickFindAllActivity {
	all: QuickFindAllActivityData;
}

export interface QuickFindMyActivity {
	myActivity: QuickFindAllActivity | null;
}

export interface QuickFindRecentActivitiesQuery {
	activity: QuickFindMyActivity | null;
}

export interface QuickFindRecentActivitiesQueryVariables {
	filteredProducts: string[];
	filteredObjectTypes: string[];
	itemsLimit: number;
	filteredRootContainerIds: string[];
}

export type QuickFindRecentActivitiesResponse = AggResponse<QuickFindRecentActivitiesQuery>;
