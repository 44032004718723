import React, { type FC, useCallback, useEffect, useRef } from 'react';
import { type WrappedComponentProps, injectIntl } from 'react-intl-next';
import { type ChildrenProps } from '..';
import { ProductSearchInput } from '../../../common/product-search-input';
import { messages } from '../../../messages';
import { useProductContext } from '../../product-router';
import { useFeaturesContext } from '../../features-context';
import { useQueryParams } from '../../query-params-context';

const debounceTime = 250;

const GenericInputWithIntl = ({
	activeProductId,
	intl,
	onNavigateGeneric,
	query,
	isExpanded,
	isLoading,
	onOpen,
	forwardRef,
	onBack,
	setQuery,
	shouldFillContainer,
}: ChildrenProps & WrappedComponentProps) => {
	const { getProduct } = useProductContext();
	const { isGriffinNavEnabled } = useFeaturesContext();
	const { queryParams } = useQueryParams();
	const queryParamText = queryParams?.text?.toString() || '';
	const onNavigateGenericInput = useCallback(
		(productId: string) => (href: string, event: React.MouseEvent | KeyboardEvent) => {
			if (href) {
				onNavigateGeneric(productId, href, event);
			}
		},
		[onNavigateGeneric],
	);

	// We're using a ref for setQuery so that we can guarantee the function
	// definition is the same reference between renders.
	const setQueryRef = useRef(setQuery);

	useEffect(() => {
		setQueryRef.current = setQuery;
	}, [setQuery]);

	useEffect(() => {
		if (isGriffinNavEnabled) {
			setQueryRef.current(queryParamText);
		}
	}, [isGriffinNavEnabled, queryParamText, setQueryRef]);

	const {
		advancedSearchUrl,
		secondaryAdvancedSearchURL,
		onNavigate,
		actionSubjectId,
		// @ts-expect-error
		expandedStateInputPlaceholder,
	} = (() => {
		if (!activeProductId || !getProduct(activeProductId)) {
			return {
				advancedSearchUrl: '',
				secondaryAdvancedSearchURL: '',
				onNavigate: isGriffinNavEnabled ? onNavigateGenericInput('') : () => {},
				actionSubjectId: 'noActiveProduct',
			};
		}

		return {
			advancedSearchUrl: getProduct(activeProductId)?.generateAdvancedSearchUrl?.(query) || '',
			secondaryAdvancedSearchURL:
				getProduct(activeProductId)?.generateAdvancedSearchUrl?.(
					query,
					undefined,
					'onInputEnterModified',
				) || '',
			expandedStateInputPlaceholder:
				getProduct(activeProductId)?.expandedStateInputPlaceholder || '',
			onNavigate: onNavigateGenericInput(activeProductId),
			actionSubjectId: activeProductId,
		};
	})();

	return (
		<ProductSearchInput
			shouldFillContainer={shouldFillContainer}
			forwardRef={forwardRef}
			value={query}
			isExpanded={isExpanded}
			isLoading={isLoading}
			onOpen={onOpen}
			debounceTime={debounceTime}
			advancedSearchURL={advancedSearchUrl}
			secondaryAdvancedSearchURL={secondaryAdvancedSearchURL}
			actionSubjectId={actionSubjectId}
			onBack={onBack}
			onInput={setQuery}
			expandedPlaceholder={
				expandedStateInputPlaceholder
					? expandedStateInputPlaceholder
					: intl.formatMessage(messages.common_search_input_collapsed_placeholder)
			}
			collapsedPlaceholder={intl.formatMessage(messages.common_search_input_collapsed_placeholder)}
			onNavigate={onNavigate}
		/>
	);
};

export const GenericProductSearchInput: FC<ChildrenProps> = injectIntl(GenericInputWithIntl);
