import { type RequestServiceOptions, utils } from '@atlaskit/util-service-support';

// This type is inferred directly from
// https://developer.atlassian.com/platform/teams-internal/rest/v4/api-group-teams/#api-api-v4-teams-teamid-get-response
export interface TeamDetails {
	creatorId?: string;
	description: string;
	displayName: string;
	externalReference?: {
		id: string;
		source: string;
	};
	id: string;
	isVerified: boolean;
	largeAvatarImageUrl: string;
	largeHeaderImageUrl: string;
	membershipSettings: string;
	organizationId?: string;
	permission?: string;
	scopeMode?: string;
	smallAvatarImageUrl: string;
	smallHeaderImageUrl: string;
	state: string;
}

export const getTeamData = (url: string, teamAri: string): Promise<TeamDetails> => {
	const parts = teamAri.split('/');
	const id = parts[parts.length - 1];
	const options: RequestServiceOptions = {
		path: `/gateway/api/v4/teams/${encodeURIComponent(id)}`,
		requestInit: {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		},
	};
	return utils.requestService<TeamDetails>({ url }, options);
};
