import React, { useState, type FunctionComponent, Suspense } from 'react';
import { FlagGroup } from '@atlaskit/flag';
import { getProductReleaseVersion } from '@atlassian/search-common';
import { useIntl } from 'react-intl-next';

import { useUserContext } from '../../common/user-context';
import {
	FeedbackButton,
	type FeedbackButtonProps,
} from '../../common/feedback-collector/feedback-button';
import { BUILD_VERSION } from '../../buildVersion';
import { useTypedAggregatorClient } from '../aggregator-client-context';
import { useProductContext } from '../product-router';
import { useFeaturesContext } from '../features-context/features-context';
import { useExperiments } from '../../common/experiment-provider';

const AkFeedbackCollector = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/product-search-dialog/feedback-collector" */ '@atlaskit/feedback-collector'
	).then((module) => {
		return {
			default: module.default,
		};
	}),
);

const AkFeedbackFlag = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/product-search-dialog/feedback-flag" */ '@atlaskit/feedback-collector'
	).then(({ FeedbackFlag }) => {
		return {
			default: FeedbackFlag,
		};
	}),
);

const HELLO_TENANT_ID = 'a436116f-02ce-4520-8fbb-7301462a1674';
const ENTRYPOINT_ID = '9b88e53b-d04d-4f1c-ae85-6d7f59d1ca7a';
const FEEDBACK_CONTEXT_CF = 'customfield_10047';

/**
 * Feedback collector for extensible tabs in Quick Search i.e. confluence tab in QS and QF
 */
const FeedbackCollector: FunctionComponent<{
	TriggerComponent?: React.FC<FeedbackButtonProps>;
}> = ({ TriggerComponent = FeedbackButton }) => {
	const { user } = useUserContext();
	const { name, id } = user;
	const [isOpen, setIsOpen] = useState(false);
	const [displayFlag, setDisplayFlag] = useState(false);
	const aggregatorClient = useTypedAggregatorClient();
	const cloudId = aggregatorClient.getAggregatorConfig().cloudId;
	const { backendExperiment, frontendExperiments = {} } = useExperiments();
	const { getActiveProduct, getPrimaryProduct } = useProductContext();
	const activeProduct = getActiveProduct()?.title;
	const primaryProduct = getPrimaryProduct()?.title;
	const isHello = HELLO_TENANT_ID === cloudId;
	const isQuickFind = useFeaturesContext().quickFind?.enabled;
	const experience = isQuickFind ? 'quick-find' : 'quick-search';
	const feedbackContext = JSON.stringify({
		experimentLayers: backendExperiment?.experimentLayers,
		frontendExperiments,
		frontendVersion: BUILD_VERSION,
		product: activeProduct,
		primaryProduct,
		cloudId,
		userId: id,
		source: 'product-search-dialog',
		isHello,
		experience,
		release: getProductReleaseVersion(primaryProduct),
	});

	const { locale } = useIntl();
	return (
		<>
			<TriggerComponent
				onClick={(e) => {
					e.preventDefault();
					setIsOpen(true);
				}}
			/>
			{isOpen ? (
				<Suspense fallback={<></>}>
					<AkFeedbackCollector
						locale={locale}
						onClose={() => setIsOpen(false)}
						onSubmit={() => setDisplayFlag(true)}
						atlassianAccountId={id}
						name={name}
						entrypointId={ENTRYPOINT_ID}
						additionalFields={[
							{
								id: FEEDBACK_CONTEXT_CF,
								value: feedbackContext,
							},
						]}
					/>
				</Suspense>
			) : null}
			{displayFlag && (
				<FlagGroup onDismissed={() => setDisplayFlag(false)}>
					<Suspense fallback={<></>}>
						<AkFeedbackFlag locale={locale} />
					</Suspense>
				</FlagGroup>
			)}
		</>
	);
};

export default FeedbackCollector;
