import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import ArrowLeftIcon from '@atlaskit/icon/core/migration/arrow-left';
import { IconButton } from '@atlaskit/button/new';

import { useTheme } from '../theme';
import { SearchIcon } from '../icons';
import {
	MobileSearchIconWrapper,
	SearchInputContainer,
	ArrowIconWrapper,
	HiddenTooltip,
} from './search-input.styled';
import type { SearchCSS } from '../theme';
import {
	EnlargedDesktopSearchIconWrapper,
	EnlargedSearchInputField,
} from './search-input-enlarged.styled';
import { EnlargedSearchInputOverlay } from './search-input-enlarged-overlay';
import { type Props as SearchInputProps } from './search-input';
import { token } from '@atlaskit/tokens';
import { N700 } from '@atlaskit/theme/colors';

interface InternalProps {
	forwardRef?: React.Ref<HTMLInputElement>;
	theme: SearchCSS;
}

const MAX_INPUT_LENGTH = 500;

export const EnlargedSearchInput = ({
	onInput,
	onClick,
	onEnter,
	isExpanded,
	placeholder,
	forwardRef,
	value,
	theme,
	tooltipContent,
	onBack,
	showClearButton,
	onClear,
	allowChangeExpand,
	exitSearchLabel,
	clearSearchLabel,
	shouldFillContainer,
	onSelect,
	ariaLabelContent,
}: SearchInputProps & InternalProps) => {
	const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
		if (onInput) {
			onInput(e.currentTarget.value);
		}
	};

	const handleClick = (e: React.MouseEvent<HTMLElement> | undefined) => {
		if (onClick) {
			onClick(e);
		}
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.nativeEvent?.isComposing || e.keyCode === 229) {
			return;
		}
		if (!e.defaultPrevented && e.key === 'Enter') {
			e.preventDefault();
			onEnter && onEnter(e);
		}
	};

	const handleSelect = (e: React.SyntheticEvent) => {
		if (onSelect) {
			onSelect(e);
		}
	};

	const TooltipComponent = tooltipContent && !isExpanded ? undefined : HiddenTooltip;

	return (
		<Tooltip content={tooltipContent} component={TooltipComponent} position="bottom">
			<SearchInputContainer
				{...theme}
				shouldFillContainer={shouldFillContainer}
				isExpanded={isExpanded}
				role="search"
			>
				<MobileSearchIconWrapper isExpanded={isExpanded}>
					<IconButton
						onClick={handleClick}
						label="Search"
						icon={() => <SearchIcon theme={theme} isExpanded={isExpanded} size="small" />}
					/>
				</MobileSearchIconWrapper>
				<EnlargedDesktopSearchIconWrapper {...theme} isExpanded={isExpanded}>
					<SearchIcon
						theme={theme}
						isExpanded={isExpanded}
						size={isExpanded ? 'medium' : 'small'}
						primaryColor={token('color.icon.subtle', N700)}
					/>
				</EnlargedDesktopSearchIconWrapper>
				{isExpanded && (
					<ArrowIconWrapper {...theme} onClick={onBack}>
						<ArrowLeftIcon
							label={exitSearchLabel || 'Exit Search'}
							LEGACY_size="large"
							color="currentColor"
						/>
					</ArrowIconWrapper>
				)}
				<EnlargedSearchInputField
					data-test-id="search-dialog-input"
					onInput={handleInput}
					onChange={handleInput}
					onKeyDown={handleKeyDown}
					onClick={handleClick}
					onSelect={handleSelect}
					isExpanded={isExpanded}
					placeholder={placeholder}
					ref={forwardRef || undefined}
					value={value}
					maxLength={MAX_INPUT_LENGTH}
					aria-label={ariaLabelContent || placeholder}
					{...theme}
				/>
				{showClearButton && (
					<EnlargedSearchInputOverlay
						theme={theme}
						showClearButton={showClearButton}
						onClear={onClear}
						allowChangeExpand={allowChangeExpand}
						clearSearchLabel={clearSearchLabel}
						isExpanded={isExpanded}
					/>
				)}
			</SearchInputContainer>
		</Tooltip>
	);
};

export default React.forwardRef<HTMLInputElement, SearchInputProps>((props, ref) => {
	const theme = useTheme();
	return <EnlargedSearchInput {...props} theme={theme} forwardRef={ref} />;
});
