import { useSessionData } from '@confluence/session-data';
import { fg } from '@confluence/feature-gating';

import { expVal } from '@confluence/feature-experiments';

export enum ExperimentParameterValues {
	CONTROL = 'quick-find-control',
	EXPERIMENT = 'quick-find-experiment',
	NOT_ENROLLED = 'not-enrolled', // This value is for the people who dont satisfy the condition of the Feature Gate , thus are excluded from the experiment
}

type AbTest = {
	experimentId: string;
	controlId: string;
	abTestId: string;
};

export const QUICK_FIND_STATSIG_EXPERIMENT = 'confluence_frontend_quick_find';

/*
	Quick find is enabled by:
	- NOT having the opt-out confluence_rovo_search_opt_out
	- Having Rovo or Unified Search (in development)
	- Being in the experiment
*/
export const useQuickFindEnabled: () => [boolean, AbTest | null | undefined] = () => {
	const { isRovoEnabled: isRovoEnabledInTCS } = useSessionData();

	// A user sees Quick Find if:
	// - They have Rovo or the FG for Unified Search beta (in development)
	// For now, the Rovo Opt Out also overrides the Unified Search FG
	const isUnifiedSearchBeta = fg('unified_search_in_confluence');
	const isOptedOutOfRovoSearch = fg('confluence_rovo_search_opt_out');

	// Opting out overrides all other checks
	if (isOptedOutOfRovoSearch) {
		return [false, null];
	}

	if (isRovoEnabledInTCS || isUnifiedSearchBeta) {
		return [
			true,
			{
				abTestId: QUICK_FIND_STATSIG_EXPERIMENT,
				controlId: ExperimentParameterValues.CONTROL,
				experimentId: ExperimentParameterValues.EXPERIMENT,
			},
		];
	}

	const quickFindExperiment = expVal('confluence_frontend_quick_find', 'cohort', 'not-enrolled');

	const serveQuickFind = quickFindExperiment === ExperimentParameterValues.EXPERIMENT; // today we are overriding the ab context only for quick find. In future when this becomes the standard we wouldnt need this condition

	if (quickFindExperiment !== ExperimentParameterValues.NOT_ENROLLED) {
		// this check ensures that we don't overwrite the /experiment API response for users who are not in the Quick Find experiment
		return [
			serveQuickFind,
			{
				abTestId: QUICK_FIND_STATSIG_EXPERIMENT,
				controlId: ExperimentParameterValues.CONTROL,
				experimentId: quickFindExperiment,
			},
		];
	}

	return [serveQuickFind, null];
};
