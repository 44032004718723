import {
	SearchResultLink,
	type SearchResultProps,
	useKeyboardHighlighting,
} from '@atlassian/search-dialog';
import React, { type FC, type PropsWithChildren, useCallback } from 'react';
import { useSearchSessionId } from '../../common/search-session-provider';
import { addQuery } from '../../utils/url-utils';
import { type SearchResult, type Section } from '../product-router/product/result-types';
import { defaultResultRenderer } from './default-result-renderer';
import { type SearchResultProps as ProductSearchResultProps } from './search-result';
import {
	type SearchResultAnalyticsCallbacks,
	useSearchResultAnalyticsCallbacks,
} from './use-search-result-analytics-callbacks';

interface SearchResultItemProps
	extends Pick<SearchResultProps, 'isCollapsed' | 'linkComponent'>,
		Pick<ProductSearchResultProps, 'productState' | 'screen' | 'appendQueryParams'>,
		Pick<Section, 'resultRenderer' | 'resultWrapper' | 'useAnalyticsOverride'> {
	/**
	 * The search result to display.
	 */
	searchResult: SearchResult;
}

const DefaultSearchResultItemWrapper = ({ children }: PropsWithChildren<{}>) => {
	return <>{children}</>;
};

/**
 * Intended to be used for search results that are not links
 */
const SearchResultItemKeyboardHiglightWrapper = ({
	searchResultId,
	children,
}: {
	searchResultId: string;
	children: (isKeyboardHighlighted: boolean) => React.ReactElement | null;
}) => {
	const { isKeyboardHighlighted, ref } = useKeyboardHighlighting({
		searchResultId,
	});

	return <div ref={ref}>{children(isKeyboardHighlighted)}</div>;
};

/**
 * This component displays a search result item in the search dialog. It
 * provides keyboard shortcuts, analytics, and displaying the search result
 * as a link. Notably, this component improves on its predecessor by allowing
 * the content of the search result item to be customised whilst still
 * retaining all of the above listed features.
 *
 * TODO: Should we explore the idea of allowing people to attach custom
 * analytics to search results? These would be two new fields searchResult
 *  - serachResult.analyticContext
 *  - searchResult.nonPrivacySafeAnalyticContext
 * These would get merged with the given analytics context. We might have some
 * issues with typing of analytics contexts though.
 */
export const SearchResultItem: FC<SearchResultItemProps> = ({
	isCollapsed,
	linkComponent,
	productState,
	resultRenderer,
	resultWrapper,
	screen,
	searchResult,
	appendQueryParams,
	useAnalyticsOverride = () => ({}) as SearchResultAnalyticsCallbacks,
}) => {
	const searchSessionId = useSearchSessionId();

	const {
		onHighlightCallback: defaultOnHighlightCallback,
		onSelectCallback: defaultOnSelectCallback,
		onContextMenuCallback: defaultOnContextMenuCallback,
	} = useSearchResultAnalyticsCallbacks({
		screen,
		actionSubject: 'searchResult',
	});

	const {
		onHighlightCallback: overriddenOnHighlightCallback,
		onSelectCallback: overriddenOnSelectCallback,
		onContextMenuCallback: overriddenOnContextMenuCallback,
	} = useAnalyticsOverride({ screen });

	const url =
		appendQueryParams === undefined
			? addQuery(searchResult.url, 'search_id', searchSessionId)
			: appendQueryParams(searchResult.url, searchSessionId, searchResult.type, searchResult.id);

	const SearchResultItemWrapper = resultWrapper || DefaultSearchResultItemWrapper;

	const searchResultContentRenderer = useCallback(
		(isKeyboardHighlighted: boolean) =>
			resultRenderer
				? resultRenderer({
						isCollapsed,
						isKeyboardHighlighted,
						productState,
						screen,
						searchResult,
					})
				: defaultResultRenderer({
						isCollapsed,
						isKeyboardHighlighted,
						screen,
						searchResult,
					}),
		[isCollapsed, productState, resultRenderer, screen, searchResult],
	);

	return (
		<SearchResultItemWrapper url={url}>
			{url ? (
				<SearchResultLink
					href={url}
					linkComponent={searchResult.linkComponent ?? linkComponent}
					searchResultId={searchResult.id}
					onContextMenu={overriddenOnContextMenuCallback || defaultOnContextMenuCallback}
					onHighlighted={overriddenOnHighlightCallback || defaultOnHighlightCallback}
					onSelect={overriddenOnSelectCallback || defaultOnSelectCallback}
					data-testid={`link-${searchResult.id}`}
				>
					{(isKeyboardHighlighted) => searchResultContentRenderer(isKeyboardHighlighted)}
				</SearchResultLink>
			) : (
				<SearchResultItemKeyboardHiglightWrapper searchResultId={searchResult.id}>
					{(isKeyboardHighlighted) => searchResultContentRenderer(isKeyboardHighlighted)}
				</SearchResultItemKeyboardHiglightWrapper>
			)}
		</SearchResultItemWrapper>
	);
};
