import React, { useCallback, Suspense } from 'react';
import {
	MetaContextProvider,
	KeyboardWrapper,
	ProductTabs,
	type MetaContextProviderProps,
	useSessionUserInput,
	type DialogFeatures,
} from '../..';
import {
	type ExternalProps as KeyboardWrapperProps,
	type KeyboardWrapperExternalChildren,
} from '../../focus-and-keyboard-wrapper/focus-and-keyboard-wrapper';
import { useTypedAggregatorClient } from '../../aggregator-client-context';
import { ActiveProductSearchInput, type ExternalProps } from '../../active-product-search-input';
import { type PageLoadMetricsInfo, type AISearchConfig } from './types';
import { initialisationExperience } from '../../../common/ufo-experiences';
import { useQuery } from '../../query-context';
import { useUserContext } from '../../../common/user-context';
import { useAppliedFilters } from '../../filters';
import { useSearchSessionId } from '../../../common/search-session-provider';
import { useDialogExpansionContext } from '../../dialog-expansion-context';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { ActiveProductProvider } from '../../product-router';
import { DisableXPSAB } from '../../ab-test-context/experiment-hoc';
import { ProductSearchDialogWrapper } from './product-search-dialog-wrapper';
import { type ParsedUrlQuery } from '../../../common/shared/types';

const ConditionalWrapper: React.FC<{
	wrapper?: React.FC;
	children: React.ReactElement;
}> = ({ wrapper, children }) => wrapper?.({ children }) ?? children;

export interface MultiProductDialogProps {
	/**
	 * A consumer would compose the product tabs i.e. JiraTab, ConfluenceTab or Bitbucket tab under this component.
	 * Usage of MultiProductDialog can be seen inside the 105-nav-v3-integrated-extensible-* stories.
	 */
	children: (args: KeyboardWrapperExternalChildren) => React.ReactElement | null;

	/**
	 * An object which controls whether or not specific features are enabled
	 */
	dialogFeatures?: DialogFeatures;

	/**
	 * This prop is used by us to instrument TTI metrics. It is fired when the dialog is ready for interaction.
	 */
	pageLoadMetrics?: PageLoadMetricsInfo;
	DialogWrapper?: React.FunctionComponent;

	/**
	 * This prop is used to render an onboarding component, for instance a dismissable spotlight message
	 */
	OnboardingRenderer?: React.FunctionComponent<{ activeProductId?: string }>;
	/**
	 * This prop is used to pass the aiSearchConfig, which contains the callback that fetches smart answers
	 */
	aiSearchConfig?: AISearchConfig;
	/**
	 * This prop indicates the edition of the product that the search dialog is opened in
	 */
	edition?: string;

	primaryProduct?: string;

	/**
	 * Used to support Griffin navigation (nav4)
	 */
	queryParams?: ParsedUrlQuery;
}

export type MultiProductDialogPropsComplete = MultiProductDialogProps &
	Omit<MetaContextProviderProps, 'children'> &
	KeyboardWrapperProps &
	ExternalProps;

const SearchAIDialogComponent = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_@atlassian/search-ai" */ '@atlassian/search-ai'
	).then(({ SearchAIDialog }) => ({
		default: SearchAIDialog,
	})),
);

const SearchAIAnswerDialog = ({
	aiSearchConfig,
	onClose,
	edition,
	closeParentDialog,
}: {
	aiSearchConfig: AISearchConfig;
	edition?: string;
	onClose: () => void;
	closeParentDialog: () => void;
}) => {
	const { query, setQueryOnly } = useQuery();
	const aggregatorClient = useTypedAggregatorClient();
	const cloudId = aggregatorClient.getAggregatorConfig().cloudId;
	const searchSessionId = useSearchSessionId();
	const { user } = useUserContext();
	const { resetSearchSession } = useSessionUserInput();
	const { allowChangeExpand } = useDialogExpansionContext();
	const filters = useAppliedFilters('confluence') as {
		'@type': string;
		[key: string]: string | string[] | boolean | undefined;
	}[];

	const onNavigate = useCallback(() => {
		resetSearchSession();
		allowChangeExpand(true);
	}, [resetSearchSession, allowChangeExpand]);

	return (
		<Suspense fallback={<></>}>
			<SearchAIDialogComponent
				query={query}
				setQuery={setQueryOnly}
				cloudId={cloudId}
				getAIAnswer={aiSearchConfig.aiSearchQueryFunction}
				edition={edition}
				followUpsEnabled={aiSearchConfig.followUpsEnabled}
				onNavigate={onNavigate}
				onClose={onClose}
				searchSessionId={searchSessionId}
				userDetails={user}
				source="searchDialog"
				parsedFilters={filters}
				closeParentDialog={closeParentDialog}
			/>
		</Suspense>
	);
};

export const MultiProductDialog = ({
	children,
	theme,
	isExpanded,
	setIsExpanded,
	abTestCloudId,
	aggregatorUrl,
	dialogFeatures,
	user,
	forwardRef,
	onNavigateGeneric,
	keepExpandedInTab,
	pageLoadMetrics,
	DialogWrapper,
	OnboardingRenderer,
	experience,
	aiSearchConfig,
	edition,
	primaryProduct,
	shouldFillContainer,
	isRovoEnabled,
	queryParams,
}: MultiProductDialogPropsComplete) => {
	useUFOComponentExperience(initialisationExperience);

	return (
		<MetaContextProvider
			theme={theme}
			experience={experience}
			isExpanded={isExpanded}
			setIsExpanded={setIsExpanded}
			abTestCloudId={abTestCloudId}
			aggregatorUrl={aggregatorUrl}
			clientProviderFeatures={dialogFeatures}
			user={user}
			dialogFeatures={dialogFeatures}
			primaryProduct={primaryProduct}
			forwardRef={forwardRef}
			isRovoEnabled={isRovoEnabled}
			queryParams={queryParams}
		>
			<KeyboardWrapper
				shouldFillContainer={shouldFillContainer}
				forwardRef={forwardRef}
				keepExpandedInTab={keepExpandedInTab}
			>
				{({ ref, onRetry }) => {
					return (
						<>
							<ActiveProductSearchInput
								shouldFillContainer={shouldFillContainer}
								forwardRef={ref}
								onNavigateGeneric={onNavigateGeneric}
								features={dialogFeatures}
								pageLoadMetrics={pageLoadMetrics}
							/>
							<ConditionalWrapper wrapper={DialogWrapper}>
								<>
									<ActiveProductProvider>
										{({ activeProduct, showSearchAIDialog, onCloseAIDialog, defaultAIConfig }) => {
											const finalAIConfig = dialogFeatures?.isGriffinNavEnabled
												? aiSearchConfig || defaultAIConfig
												: aiSearchConfig;
											return showSearchAIDialog && finalAIConfig && isExpanded ? (
												<SearchAIAnswerDialog
													aiSearchConfig={finalAIConfig}
													onClose={onCloseAIDialog}
													edition={edition}
													closeParentDialog={() => setIsExpanded(false)}
												/>
											) : (
												<ProductSearchDialogWrapper shouldFillContainer={shouldFillContainer}>
													{OnboardingRenderer && isExpanded && (
														<DisableXPSAB>
															<OnboardingRenderer activeProductId={activeProduct?.id} />
														</DisableXPSAB>
													)}
													<ProductTabs />
													{children({ ref, onRetry })}
												</ProductSearchDialogWrapper>
											);
										}}
									</ActiveProductProvider>
								</>
							</ConditionalWrapper>
						</>
					);
				}}
			</KeyboardWrapper>
		</MetaContextProvider>
	);
};
