import React from 'react';
import {
	FormattedMessage,
	type MessageDescriptor,
	type WrappedComponentProps,
	injectIntl,
} from 'react-intl-next';
import { getTrigger, isNewTab } from '../../../common/analytics';
import { AdvancedSearchLinkSubjectId } from '../../../common/analytics/events';
import { messages } from '../../../messages';
import { type JiraFeatures, type AdvancedSearchLinksProps } from './types';
import { useQuery } from '../../query-context';

import {
	AdvancedSearchContainer,
	LabelContainer,
	StyledLink,
} from './single-site-advanced-search.styled';

type SearchConfiguration = {
	href: string;
	message: MessageDescriptor;
	actionSubjectId: AdvancedSearchLinkSubjectId;
};

export const getSearchConfiguration: (
	query: string,
	features?: JiraFeatures,
) => { [key: string]: SearchConfiguration } = (query, features) => {
	let allLinks: { [key: string]: SearchConfiguration } = {
		board: {
			href: `/secure/ManageRapidViews.jspa?contains=${query}`,
			message: messages.jira_advanced_search_boards,
			actionSubjectId: AdvancedSearchLinkSubjectId.JIRA_BOARDS,
		},
		project: {
			href: `/projects?contains=${query}`,
			message: messages.jira_advanced_search_projects,
			actionSubjectId: AdvancedSearchLinkSubjectId.JIRA_PROJECTS,
		},
		filters: {
			href: `/secure/ManageFilters.jspa?Search=Search&filterView=search&name=${query}`,
			message: messages.jira_advanced_search_filters,
			actionSubjectId: AdvancedSearchLinkSubjectId.JIRA_FILTERS,
		},
		plans: {
			href: `/jira/plans`,
			message: messages.jira_advanced_search_plans,
			actionSubjectId: AdvancedSearchLinkSubjectId.JIRA_PLANS,
		},
		people: {
			href: `/jira/people/search?q=${query}`,
			message: messages.jira_advanced_search_people,
			actionSubjectId: AdvancedSearchLinkSubjectId.JIRA_PEOPLE,
		},
	};

	// Remove access to plans if the user does not have Advanced Roadmaps access
	if (!features?.hasAdvancedRoadmapsAccess) {
		delete allLinks.plans;
	}
	// Remove access to boards if the user does not have software access
	if (!features?.hasAdvancedRoadmapsAccess && !features?.hasSoftwareAccess) {
		delete allLinks.board;
	}

	return allLinks;
};

export const SingleSiteAdvancedSearch: React.FC<
	AdvancedSearchLinksProps & WrappedComponentProps
> = ({ advancedSearchSelected, features, linkComponent }) => {
	const { query, isLoading } = useQuery();

	const onClickAndFireEvent = (
		e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
		actionSubjectId: AdvancedSearchLinkSubjectId,
	) => {
		e.preventDefault();
		advancedSearchSelected({
			actionSubjectId,
			trigger: getTrigger(e),
			isLoading,
			newTab: isNewTab(e),
		});
	};

	const searchConfiguration = getSearchConfiguration(query, features);

	return (
		<AdvancedSearchContainer>
			<LabelContainer>
				<FormattedMessage {...messages.jira_advanced_search_label} />
			</LabelContainer>
			{Object.keys(searchConfiguration).map((key) => {
				const { href, message, actionSubjectId } = searchConfiguration[key];

				return (
					<span
						onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
							onClickAndFireEvent(e, actionSubjectId)
						}
						role="none"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={{ height: '100%' }}
					>
						<StyledLink linkComponent={linkComponent} key={`search_link_${key}`} href={href}>
							<FormattedMessage {...message} />
						</StyledLink>
					</span>
				);
			})}
		</AdvancedSearchContainer>
	);
};

export default injectIntl(SingleSiteAdvancedSearch);
