import { useMemo } from 'react';
import { useTypedAggregatorClient, PLACEHOLDER_EXPERIENCE } from '../../aggregator-client-context';
import { type PermissionSupplier } from '../../product-router';
import {
	type AggregatorBitbucketResponse,
	type BitbucketScope,
	BITBUCKET_PRODUCT_ID,
} from './types';

export const useBitbucketPermissionSupplier = (
	overridenPermissionsSupplier?: PermissionSupplier,
) => {
	const client = useTypedAggregatorClient<AggregatorBitbucketResponse, BitbucketScope>();

	return useMemo(() => {
		if (overridenPermissionsSupplier) {
			return overridenPermissionsSupplier;
		}

		return () => client.getProductScopes(BITBUCKET_PRODUCT_ID, PLACEHOLDER_EXPERIENCE);
	}, [client, overridenPermissionsSupplier]);
};
