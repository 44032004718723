// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { N400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KeyboardShortcutContainer = styled.div({
	display: 'flex',
	lineHeight: '16px',
	alignItems: 'center',
	paddingBottom: token('space.025', '2px'),
	paddingTop: token('space.025', '2px'),
});

// TODO: https://product-fabric.atlassian.net/browse/DSP-3457
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const KeyboardShortcut = styled.div({
	backgroundColor: token('color.background.neutral', N400),
	border: `1px solid ${token('color.text.inverse', N400)}`,
	borderRadius: '2px',
	marginLeft: token('space.050', '4px'),
	padding: `${token('space.025', '2px')} ${token('space.100', '8px')}`,
	lineHeight: '16px',
});
