import { SearchResultContent, type SearchResultContentProps } from '@atlassian/search-dialog';
import React from 'react';
import { type ScreenType } from '../../common/analytics';
import { ResultAvatar } from '../../common/result-avatar';
import { type SearchResult } from '../product-router/product/result-types';

interface DefaultResultRendererArgs
	extends Pick<SearchResultContentProps, 'isCollapsed' | 'isKeyboardHighlighted'> {
	screen: ScreenType;
	searchResult: SearchResult;
}

/**
 * This function renders a search result using the default component offered
 * by the search platform. Specifically, the SearchResultContent is used.
 */
export const defaultResultRenderer = (args: DefaultResultRendererArgs) => {
	const { isCollapsed, isKeyboardHighlighted, screen, searchResult } = args;

	const icon = React.isValidElement(searchResult.icon) ? (
		searchResult.icon
	) : typeof searchResult.icon === 'string' ? (
		<ResultAvatar
			borderColor="transparent"
			src={searchResult.icon}
			appearance="square"
			size="xsmall"
			testId="result-avatar"
		/>
	) : searchResult.iconRenderer ? (
		searchResult.iconRenderer({ screen })
	) : (
		<ResultAvatar
			borderColor="transparent"
			src={searchResult.iconUrl}
			appearance="square"
			size="xsmall"
			testId="result-avatar"
		/>
	);

	return (
		<SearchResultContent
			key={`item_${searchResult.id}`}
			isKeyboardHighlighted={isKeyboardHighlighted}
			title={searchResult.title}
			meta={searchResult.meta}
			additionalMeta={searchResult.additionalMeta}
			isCollapsed={isCollapsed}
			icon={icon}
		/>
	);
};
