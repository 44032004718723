/* eslint-disable @atlaskit/ui-styling-standard/no-nested-selectors */

// Copied from confluence/next/packages/navigation-search-dialog/src/suppliers/utils/ConfluenceResultMeta.tsx

import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';
import { N300 } from '@atlaskit/theme/colors';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import { SpacerWrapper, capitalizeFirstLetter } from './quick-find-meta';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfluenceResultMetaWrapper = styled.span({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbsWrapper = styled.div({
	'nav > ol': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
		'li:not(:last-child)::after': {
			padding: `0px ${token('space.050', '4px')}`,
			width: '4px',
			color: token('color.text.subtle', N300),
		},
		li: {
			height: '14px',
			lineHeight: '14px',
		},
		span: {
			color: token('color.text.subtle', N300),
		},
		flexWrap: 'nowrap',
		'li a': {
			alignItems: 'center',
			pointerEvents: 'none',
			maxWidth: '200px',
			font: token('font.body.small'),
			lineHeight: '14px',
			padding: '0px',
		},
		'li button': {
			pointerEvents: 'none',
			font: token('font.body.small'),
			lineHeight: '14px',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfluenceTypeWrapper = styled.span({
	color: token('color.text.subtle', N300),
});

type ConfluenceResultAncestor = {
	id: string;
	title: string | null;
};

type ConfluenceResultSpace = {
	id: string | null;
	name?: string | null;
};

type ConfluenceResultMetaProps = {
	resultContentType: string;
	resultAncestors?: ConfluenceResultAncestor[] | null;
	resultSpace?: ConfluenceResultSpace | null;
};

export const ConfluenceResultMeta = ({
	resultContentType,
	resultAncestors,
	resultSpace,
}: ConfluenceResultMetaProps) => {
	const breadcrumbs = getBreadcrumbs(resultAncestors, resultSpace);

	return (
		<ConfluenceResultMetaWrapper>
			<ConfluenceTypeWrapper>Confluence</ConfluenceTypeWrapper>
			<SpacerWrapper>•</SpacerWrapper>
			<ConfluenceTypeWrapper>{`${capitalizeFirstLetter(resultContentType)}`}</ConfluenceTypeWrapper>
			{breadcrumbs?.length && (
				<>
					<SpacerWrapper>•</SpacerWrapper>
					<BreadcrumbsWrapper>
						<Breadcrumbs maxItems={2} isExpanded={false} onExpand={(e: any) => e.preventDefault()}>
							{breadcrumbs.map(({ id, title }, itemIndex) => (
								<BreadcrumbsItem
									key={id}
									text={title || ''}
									truncationWidth={
										itemIndex === breadcrumbs.length - 1 || itemIndex === 0 ? 130 : 50
									}
								/>
							))}
						</Breadcrumbs>
					</BreadcrumbsWrapper>
				</>
			)}
		</ConfluenceResultMetaWrapper>
	);
};

export const getBreadcrumbs = (
	ancestors: ConfluenceResultAncestor[] | null | undefined,
	space: ConfluenceResultSpace | null | undefined,
) => {
	let breadcrumbs: { id?: string | null; title?: string | null }[] = [];

	if (space) {
		const spaceBreadcrumb = { id: space?.id, title: space?.name };

		if (!ancestors || ancestors.length === 0) {
			breadcrumbs = [spaceBreadcrumb];
		} else if (ancestors.length >= 1) {
			breadcrumbs = [spaceBreadcrumb, ...ancestors.slice(1)];
		}
	}

	return breadcrumbs;
};
