import React, {
	createContext,
	useContext,
	useEffect,
	useState,
	type PropsWithChildren,
} from 'react';
import { useIntl } from 'react-intl-next';

import { useQuery } from '../query-context';
import {
	fetchAutocorrection,
	sanitizeLanguage,
	shouldFetchAutocorrection,
	useAutocorrectionEnabled,
} from './autocorrection-helper-functions';
import { useTypedAggregatorClient } from '../aggregator-client-context';

type correctionType = {
	term: string;
	distance: number;
}[];

const NO_CORRECTION: correctionType = [];

interface AutocorrectionContextType {
	correction?: correctionType;
	setCorrection: (correction: correctionType) => void;
}

const AutocorrectionContext = createContext<AutocorrectionContextType>({
	correction: undefined,
	setCorrection: () => {},
});

export const AutocorrectionProvider = ({ children }: PropsWithChildren<{}>) => {
	const { autocorrectionEnabled } = useAutocorrectionEnabled();
	const [correction, setCorrection] = useState<correctionType>(NO_CORRECTION);
	const { query } = useQuery();
	const { locale } = useIntl();
	const lang = sanitizeLanguage(locale);
	const aggregatorClient = useTypedAggregatorClient();
	const { url: aggregatorUrl, cloudId } = aggregatorClient.getAggregatorConfig();

	useEffect(() => {
		setCorrection(NO_CORRECTION);
		if (autocorrectionEnabled && shouldFetchAutocorrection(query)) {
			fetchAutocorrection({
				query,
				aggregatorUrl,
				lang,
				cloudId,
			})
				.then((result) => {
					if (!!result.termCorrections) {
						setCorrection(result.termCorrections);
					}
				})
				.catch((_error_) => {});
		}
	}, [autocorrectionEnabled, query, aggregatorUrl, cloudId, lang]);

	return (
		<AutocorrectionContext.Provider value={{ correction, setCorrection }}>
			{children}
		</AutocorrectionContext.Provider>
	);
};

export const useAutocorrection = () => {
	return useContext(AutocorrectionContext);
};
