import React from 'react';
import { type LinkComponent } from '../search-link-component';
import { ResultLink } from './search-result.styled';
import { useKeyboardHighlighting } from './use-keyboard-navigation';

export interface SearchResultLinkProps {
	/**
	 * Function which accepts isKeyboardHighlighted to render search content
	 */
	children: (isKeyboardHighlighted: boolean) => React.ReactElement | null;

	/**
	 * Link of the result.
	 */
	href: string;

	/**
	 * Toggle for whether to show the search results in a collapsed mode. The
	 * collapsed mode is used when width is restricted (e.g. filter pane enabled,
	 * mobile view). This mode will take up more vertical width.
	 */
	isCollapsed?: boolean;

	/**
	 * This will replace all links with the given component. This will be used to
	 * ensure that results provided will be properly SPA transitioned.
	 *
	 * Do not use this for styling.
	 */
	linkComponent?: LinkComponent;

	/**
	 * Unique identifier for the search result
	 */
	searchResultId?: string;

	/**
	 * Callback that is called when the result item is clicked.
	 */
	onSelect?: (e: React.MouseEvent | KeyboardEvent) => void;

	/**
	 * Callback that is called when the element is highlighted via keyboard
	 * navigation (this will not trigger on focus).
	 */
	onHighlighted?: () => void;

	/**
	 * Callback that is called when the result item is right clicked.
	 */
	onContextMenu?: (e: React.MouseEvent | KeyboardEvent) => void;
}

/**
 * This component is intended to be used as a wrapper around
 * search result content. The purpose of this component is to:
 *  - Wrap the content in a default <a></a> tag (can be overriden)
 *  - Provide keyboard shortcuts and higlighting
 *
 * The styles of this component has been designed to be as least restrictive
 * as possible. The only opinion it makes is that results are displayed as a
 * list. We may consider adding a styles override in the future.
 */
export const SearchResultLink: React.FC<Omit<SearchResultLinkProps, 'isCollapsed'>> = ({
	children,
	href,
	linkComponent,
	searchResultId,
	onSelect,
	onHighlighted,
	onContextMenu,
	...rest
}) => {
	const { isKeyboardHighlighted, ref } = useKeyboardHighlighting({
		searchResultId,
		onHighlighted,
	});

	return (
		<div ref={ref} onClick={onSelect} onContextMenu={onContextMenu} role="none">
			<ResultLink linkComponent={linkComponent} href={href} {...rest}>
				{children(isKeyboardHighlighted)}
			</ResultLink>
		</div>
	);
};
