import React from 'react';
import { Text } from '@atlaskit/primitives';
import { useIntl } from 'react-intl-next';

import { type TitleRendererArgs } from '../../product-router/product/result-types';
import { messages } from '../../../messages';
import { ProductStates } from '../../product-state-machine/product-state-machine-types';
import { LoadingSkeleton } from '../../autocorrection/autocorrection.styled';
import { AutocorrectAsyncWrapper } from '../../autocorrection';

export const sectionTitleRenderer = (autocorrectEnabled: boolean) => (args: TitleRendererArgs) => {
	const { formatMessage } = useIntl();
	const { productState } = args;

	const recentTitle = <Text>{formatMessage(messages.common_recently_viewed_section_heading)}</Text>;
	const issuesTitle = <Text>{formatMessage(messages.jira_issues_section_heading)}</Text>;

	switch (productState) {
		case ProductStates.PreQuerySuccess:
			return recentTitle;
		case ProductStates.PostQueryLoading:
			if (autocorrectEnabled) {
				return <LoadingSkeleton data-testid={'autocorrection-loading-skeleton'} />;
			}
			return issuesTitle;
		case ProductStates.PostQuerySuccess:
			if (autocorrectEnabled) {
				return <AutocorrectAsyncWrapper fallback={issuesTitle} />;
			}
			return issuesTitle;
		default:
			return issuesTitle;
	}
};
