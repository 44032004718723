import { type IntlShape, useIntl } from 'react-intl-next';
import { type Section } from '../../extensible/product-router/product/result-types';

const ACTIVE_AND_NON_BOT_USER_SEARCH_FILTER =
	'account_status:"active" AND (NOT email_domain:"connect.atlassian.com") AND (NOT account_type:app)';

export type UserTeamConfig = {
	context: UserTeamContext;
	baseUrl?: string;
	resolveTeamUrl?: (teamId: string) => string;
	resolveUserUrl?: (userId: string) => string;
};

type UserTeamContext = {
	containerId?: string;
	contextType: string;
	objectId?: string;
	sessionId?: string;
	principalId?: string;
	childObjectId?: string;
	productKey: string;
	siteId: string;
	organizationId?: string;
};

type UserTeamOptions = {
	context: UserTeamContext;
};

type RecommendationOptions = {
	includeUsers?: boolean;
	includeTeams?: boolean;
	query?: string;
	baseUrl?: string;
	maxNumberOfResults: number;
};

type RecommendationRequest = RecommendationOptions & UserTeamOptions;

const resolveRecommendationRequest = ({
	baseUrl,
	context,
	includeUsers,
	includeTeams,
	query,
	maxNumberOfResults,
}: RecommendationRequest) => ({
	baseUrl,
	context,
	maxNumberOfResults,
	query: query ?? '',
	searchQueryFilter: ACTIVE_AND_NON_BOT_USER_SEARCH_FILTER,
	includeUsers: includeUsers ?? false,
	includeGroups: false,
	includeTeams: includeTeams ?? false,
});

const asyncGetUserRecommendations = async (request: RecommendationRequest, intl: IntlShape) => {
	try {
		const { getUserRecommendations } = await import(
			/* webpackChunkName: "@atlaskit-internal_@atlassian/product-search-dialog/getUserRecommendations" */ '@atlaskit/smart-user-picker'
		);
		return getUserRecommendations(request, intl);
	} catch (e) {
		// Ignore
	}
};

type PeopleAndTeamSections = {
	people: Section;
	teams: Section;
};

export const usePeopleAndTeamsQuery = (
	peopleAndTeamSections: PeopleAndTeamSections,
	maxItemsPerSection?: number,
) => {
	const intl = useIntl();
	return async (
		query: string,
		{ baseUrl, context, resolveTeamUrl, resolveUserUrl }: UserTeamConfig,
	) => {
		const teamsRequest = resolveRecommendationRequest({
			includeTeams: true,
			baseUrl,
			context,
			query,
			maxNumberOfResults: 20,
		});
		// Separate requests enables better empty query results for each section
		const usersRequest = resolveRecommendationRequest({
			includeUsers: true,
			baseUrl,
			context,
			query,
			maxNumberOfResults: 20,
		});

		try {
			const [teams, users] = await Promise.all([
				asyncGetUserRecommendations(teamsRequest, intl),
				asyncGetUserRecommendations(usersRequest, intl),
			]);

			const teamResults =
				teams?.map((team) => {
					return {
						title: team.name,
						id: team.id,
						meta: '',
						url: resolveTeamUrl ? resolveTeamUrl(team.id) : `/people/team/${team.id}`,
						iconUrl: team.avatarUrl,
					};
				}) ?? [];

			const userResults =
				users?.map((user) => {
					return {
						title: user.name,
						id: user.id,
						meta: user.title || '',
						url: resolveUserUrl ? resolveUserUrl(user.id) : `/people/${user.id}`,
						iconUrl: user.avatarUrl,
					};
				}) ?? [];

			return {
				sections: [
					{
						...peopleAndTeamSections.people,
						searchResults: maxItemsPerSection
							? userResults.slice(0, maxItemsPerSection)
							: userResults,
					},
					{
						...peopleAndTeamSections.teams,
						searchResults: maxItemsPerSection
							? teamResults.slice(0, maxItemsPerSection)
							: teamResults,
					},
				],
			};
		} catch (error) {
			return {
				sections: [
					{
						...peopleAndTeamSections.people,
						searchResults: [],
					},
					{
						...peopleAndTeamSections.teams,
						searchResults: [],
					},
				],
			};
		}
	};
};
