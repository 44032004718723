import React, { useMemo, type ReactElement } from 'react';
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl-next';

import { messages } from '../../../messages';
import { type ResultRendererArgs, type SearchResult } from '../../product-router';
import { type GenericFilter } from '../../filters/filter-types';

import {
	JiraScope,
	type JiraScopeItemResponse,
	type SectionDetailsByAccess,
	type SupportedJiraScopeResponses,
	ISSUE_LIST_LIMIT,
	OTHERS_LIST_LIMIT_NO_ADVANCED_ROADMAPS_ACCESS,
	OTHERS_LIST_LIMIT_ADVANCED_ROADMAPS_ACCESS,
	type SupportedAttributes,
} from './types';

import { type Section, type ViewAllRendererArgs } from '../../product-router/product/result-types';
import { type JiraResultWithType, useQueryParams } from './jira-analytics-query-params';
import { AttributeType } from '../../../jira/clients/response-types';

import { defaultResultRenderer } from '../../../extensible/search-result/default-result-renderer';
import { HoverCardWrapper } from './sections.styled';
import { getTimeDetailForJiraIssueResult } from './utils';
import { sectionTitleRenderer } from './title-renderer';
import ErrorBoundary from '../../../error-boundary';
import { CardAction } from '@atlaskit/smart-card';

const HoverCard = React.lazy(() =>
	import(
		/* webpackChunkName: "@atlaskit-internal_smart-card/hover-card" */ '@atlaskit/smart-card/hover-card'
	).then((mod) => ({ default: mod.HoverCard })),
);

const HoverCardResultWrapper: Section['resultWrapper'] = ({ children, url = '' }) => {
	const hidePreviewAction = useMemo(() => {
		return { hide: false, exclude: [CardAction.PreviewAction] };
	}, []);

	return (
		<HoverCardWrapper>
			<ErrorBoundary fallback={children}>
				<React.Suspense fallback={children}>
					<HoverCard url={url} actionOptions={hidePreviewAction}>
						{children}
					</HoverCard>
				</React.Suspense>
			</ErrorBoundary>
		</HoverCardWrapper>
	);
};

const viewAllLinkRendererIssues = ({ section, totalResults }: ViewAllRendererArgs) => {
	const isTotalItemsGreaterThanTheLimit =
		(totalResults || section.searchResults?.length) > (section.resultLimit || 0);
	return isTotalItemsGreaterThanTheLimit ? (
		<FormattedMessage {...messages.common_show_all_results_link} />
	) : (
		<></>
	);
};

export const useSections = (
	hasAdvancedRoadmapsAccess: boolean,
	hasSoftwareAccess: boolean,
	viewAllLinkGenerator: (query: string) => string,
	isPreviewOnJiraSearchResultsEnabled: boolean,
	intl: IntlShape,
	formatDate?: (lastModified: string) => ReactElement,
	isConsistentIssueDetailsEnabled?: boolean,
	autocorrectEnabled?: boolean,
) => {
	const appendQueryParams = useQueryParams();
	const mapJiraItems = (
		response: SupportedJiraScopeResponses,
	): SearchResult<JiraResultWithType>[] =>
		response.results.map((item: JiraScopeItemResponse) => {
			const base = {
				id: item.id,
				title: item.name,
				url: appendQueryParams(item.url || '', item),
				meta: '',
				iconUrl: item.attributes?.avatar?.url || '',
				type: (item.attributes as SupportedAttributes)['@type'],
			};
			if (item.attributes && item.attributes['@type'] === AttributeType.issue) {
				return {
					...base,
					title: `${item.attributes?.key}  ${item.name}`,
					meta: item.attributes?.container?.title || '',
					containerId: item.attributes?.containerId,
					additionalMeta: getTimeDetailForJiraIssueResult(
						intl,
						formatDate,
						item.attributes?.updated,
					),
				};
			}
			if (item.attributes && item.attributes['@type'] === AttributeType.board) {
				return {
					...base,
					meta: item.attributes?.container?.title || '',
					containerId: item.attributes?.containerId,
				};
			}
			return base;
		});

	const getSectionDetailsByAccess = (): SectionDetailsByAccess => {
		if (hasAdvancedRoadmapsAccess) {
			return {
				scope: JiraScope.BoardProjectFilterPlans,
				recentHeading: messages.jira_recent_boards_projects_filters_plans_section_heading,
				searchHeading: messages.jira_boards_projects_filters_plans_section_heading,
			};
		}
		if (hasSoftwareAccess) {
			return {
				scope: JiraScope.BoardProjectFilter,
				recentHeading: messages.jira_recent_boards_projects_filters_section_heading,
				searchHeading: messages.jira_boards_projects_filters_section_heading,
			};
		}
		return {
			scope: JiraScope.ProjectFilter,
			recentHeading: messages.jira_recent_projects_filters_section_heading,
			searchHeading: messages.jira_projects_filters_section_heading,
		};
	};

	const { formatMessage } = useIntl();

	const secondSectionDetails = getSectionDetailsByAccess();

	const enableNoIssueSection = (query: string, filters: GenericFilter[]) =>
		!filters.find(
			(filter) =>
				filter['accountIds']?.length > 0 ||
				filter['projectIds']?.length > 0 ||
				filter['binaryStatusCategories']?.length > 0 ||
				filter['issueLabels']?.length > 0,
		);

	const resultRenderer = () => {
		return (args: ResultRendererArgs<SearchResult>) =>
			defaultResultRenderer(
				isConsistentIssueDetailsEnabled
					? {
							...args,
							isCollapsed: true,
						}
					: args,
			);
	};

	const sections = [
		{
			id: JiraScope.Issue,
			title: formatMessage(messages.jira_issues_section_heading),
			titleRenderer: sectionTitleRenderer(!!autocorrectEnabled),
			scope: JiraScope.Issue,
			resultMapper: mapJiraItems,
			showResultCount: true,
			resultLimit: ISSUE_LIST_LIMIT,
			viewAllLinkRenderer: viewAllLinkRendererIssues,
			resultRenderer: resultRenderer(),
			viewAllLinkGenerator,
			resultWrapper: isPreviewOnJiraSearchResultsEnabled ? HoverCardResultWrapper : undefined,
		},

		{
			id: secondSectionDetails.scope,
			title: formatMessage(secondSectionDetails.searchHeading),
			scope: secondSectionDetails.scope,
			resultMapper: mapJiraItems,
			isEnabled: enableNoIssueSection,
			resultLimit: hasAdvancedRoadmapsAccess
				? OTHERS_LIST_LIMIT_ADVANCED_ROADMAPS_ACCESS
				: OTHERS_LIST_LIMIT_NO_ADVANCED_ROADMAPS_ACCESS,
		},
	];

	const recentSectionTitleGenerator: <T = {}>(section: Section<T>) => string = (section) =>
		section.id === JiraScope.Issue
			? formatMessage(messages.jira_recently_viewed_issues_section_heading)
			: formatMessage(secondSectionDetails.recentHeading);

	return {
		recentSectionTitleGenerator,
		sections,
		mapJiraItems,
		getSectionDetailsByAccess,
	};
};
