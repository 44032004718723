type useConfluenceScopesProps = {
	isQuickSearchForWhiteboardsEnabled: boolean;
	isQuickSearchForDatabasesEnabled: boolean;
	isFolderEnabled: boolean;
};

type useConfluenceScopesType = {
	contentScope: string;
	extensibleScopes: string[];
};

export const useConfluenceScopes = ({
	isQuickSearchForWhiteboardsEnabled,
	isQuickSearchForDatabasesEnabled,
	isFolderEnabled,
}: useConfluenceScopesProps): useConfluenceScopesType => {
	let contentScope: string = 'confluence.page,blogpost,attachment,embed';
	const spaceAndUserScope: string[] = ['confluence.space', 'urs.user-confluence'];

	if (isQuickSearchForWhiteboardsEnabled) {
		contentScope = `${contentScope},whiteboard`;
	}
	if (isQuickSearchForDatabasesEnabled) {
		contentScope = `${contentScope},database`;
	}
	if (isFolderEnabled) {
		contentScope = `${contentScope},folder`;
	}

	return {
		contentScope,
		extensibleScopes: [...[contentScope], ...spaceAndUserScope],
	};
};
